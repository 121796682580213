import React from "react"
import Layout from "../../components/global/layout"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import Seo from "../../components/global/seo"
import SeoHead from "../../components/global/seoHead"

import PrivacyFeaturedImage from "../../../static/home/wowmakers.jpeg"
import '../../sass/pages/privacy-policy.scss'
export const Head = () => (
  <>
    <SeoHead
      ogImage={PrivacyFeaturedImage}
      title="Legal Privacy Policy and Terms of Use | WowMakers"
      description="We’re committed to our users’ rights to privacy, and we ensure your protection online. See all the steps we take to ensure your privacy and protection."
    />
  </> 
)

const PrivacyPolicy = () => (
  <Layout>
    <Seo
      bclass="privacy-policy"
      bid="privacy-policy"
    />
      <ScrollIndicationHeightZero />
      <section className="privacy-main">
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-md-12 text-center">
                    <h1 className="sub-title mobile-margin-left-right">Privacy Policy</h1>
                    <p className="sub-desc col-centered mobile-margin-left-right">Updated on : 16 June, 2021.</p>
                </div>
                <div className="col-md-10 wrapper-main">
                    <p>Welcome and thank you for your interest in WowMakers.</p>
                    <p className="wrapper-main">At WowMakers, we believe that trust is the most important part of any relationship and
                        we are committed to keeping the trust of our customers. We respect and value your
                        privacy, and we are committed to maintaining the privacy of your personal information
                        and we do not use or disclose your personal information in any way beyond what is
                        mentioned in this policy. We believe that information-gathering and its use should go
                        hand-in-hand with transparency. This Privacy Policy applies to all current and former
                        visitors to our website. By visiting and/or using our website, you agree that the terms
                        and conditions of this policy will apply to you and to any information that you may
                        provide us.
                    </p>
                    <p>Please take time to read through this policy to get acquainted with our practices
                        regarding collection, use, and disclosure of your personal information.
                    </p>
                    <h3 className="wrapper-main">Meanings</h3>
                    <p>For this Privacy Policy-</p>
                    <ul className="privacy">
                        <li>
                            You is referred to the person who accessed the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                        </li>
                        <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Zero Gravity Technologies LLP (WowMakers).</li>
                        <li>Website is referred to www.wowmakers.com</li>
                        <li>Service Provider means any natural or legal person who processes the data on       behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
                        <li>Personal Data is any information that relates to an identified or identifiable individual.</li>
                        <li>Cookies are a small amount of data generated by a website and saved by your web browser. Its purpose is to remember information about you, similar to a preference file created by a software application.</li>
                        <li>Device could be any device with internet who can access the service such as a mobile phone, a computer or a digital tablet.</li>
                        <li>Usage Data is referred to the data that has been collected automatically, either generated by the use of the Service or from the Service infrastructure itself. </li>
                    </ul>
                    <h3 className="wrapper-main">What Information do we Collect?  </h3>
                    <p>For the purposes of this privacy statement, 'Personal Information' is any data which relates to an individual who may be identified from that data, or from a combination of a set of data.</p>
                    <p>In general, you may browse our website without revealing any information about yourself. However, certain information such as your IP address may be collected in order to measure the number of visits, average time spent on the site, pages viewed, etc.</p>
                    <p>When you register on our website or would like to comment on our content, we may require you to provide us with certain Personal Information such as your name, email address, designation, company, country and telephone number, for one or more of the following purposes: identifying you, contacting you and provide access to desirable content based on your preferences. While accessing certain sections of our website such as Careers, you may also be required to provide additional Personal Information for WOWMAKERS to process the corresponding request.
                    </p>
                    <h3 className="wrapper-main">Collection of information from Children</h3>
                    <p>WowMakers does not knowingly collect data of children under 13 years of age, without the permission of parent(s)/guardian(s). If we come to know that we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe that we might have any information of a child under 13 year of age, please let us know, so that we can delete it. We strongly recommend that Users under the age of 13 would ask their parents for permission before entering any information in our website.</p>
                    <h3 className="wrapper-main">How Do we use the Information We Collect?</h3>
                    <p>
                        To respond to your query: WOWMAKERS would use the information shared by you, to respond to business enquiries from you.
                    </p>
                    <p>To provide information based on your need: Based on your selection to subscribe to our Blog and Newsletter, WOWMAKERS would use the personal information to periodically send you the corporate newsletter and share with you, the updates on our Blog. WOWMAKERS would use the personal information shared through our careers webpage and electronic mail, to contact you regarding employment opportunities.</p>
                    <p>To improve quality and usability of the websites: WOWMAKERS would use the personal information that you share, including information regarding site navigation, to improve the website, enhance page response time and to improve usability of the website.</p>
                    <p>To communicate with you about the events that we organize: WOWMAKERS would use the personal information that you share on the website, including on the event registrations page, to communicate with you about events that we organize or participate in. WOWMAKERS would also request your personal information at venue of such event that we host, visit or participate. Personal Information collected by WOWMAKERS at the venue of events that we host, visit or participate will also be subject to this policy. At the venue of events that we host, visit or participate, as on the pages in this website, you may share the personal information at your own discretion.</p>
                    <p>You may choose to share your resumes for recruitment purposes in our career page. We recommend that you do not disclose any of your sensitive personal information. We will use your personal information for recruitment purposes, and if you are offered a job or become employed by WOWMAKERS, we will use it for other employment-related purposes, including background verification. </p>
                    <h3 className="wrapper-main">Cookies and other tracking Technologies.</h3>
                    <p>When you visit our Websites we may send a ‘cookie’ to your computer. Cookies are files that store information on your hard drive or browser. They enable our Websites to identify your computer, and recognise that you have visited before. Cookies themselves do not contain any personal information. However, we may link the information we store in cookies to any personally identifiable information you submit while on our site. Cookies help us understand which sections of our Websites are the most popular; as they help show which pages are being visited and how long for.  This helps WOWMAKERS adapt our Websites to provide more relevant and accessible information. For example, cookies allow us to ensure that the information displayed on your next visit to our Websites will match up with your user preferences. You may configure your browser to accept all cookies, reject all cookies, or notify you when a cookie is sent. Please note, however, that disabling cookies might affect your online experience and/or prevent you from taking full advantage of our site.</p>
                    <h3 className="wrapper-main">What control do you have on your information that we collect?</h3>
                    <p>To request a written copy of the personal information WOWMAKERS holds about you and to have any inaccurate information about you corrected, you may please contact the Data Protection Office of WOWMAKERS. (hello@wowmakers.com) You may request us to stop using your details for marketing and other promotional activities by using the unsubscribe method provided in our various communications.</p>
                    <p>If you choose to withhold any Personal Information requested by us, it may not be possible for you to gain access to certain parts of the website and for us to respond to your query.
                    </p>
                    <h3 className="wrapper-main">Information We Share with Your Consent:</h3>
                    <p>Except as set forth above, you will be notified when your Personal Information may be shared with third parties, and will be able to prevent the sharing of this information.</p>
                    <h3 className="wrapper-main">Changes to Our Privacy Policy</h3>
                    <p>We truly feel that our privacy policy reflects our culture and practices and also comply with the changes in   the law. It may be necessary to change this Privacy Policy and we will always notify you before any change happens. We recommend you to visit this page periodically to stay aware of any changes to this policy.   If we make any changes to this Privacy policy, we will make the revised version available in this webpage.    The date on which   the policy was revised will be    indicated on the top of this Policy page. The   modifications will be effective from the date on which the   revised version is published in this web page.   As this change occurs and we continue to serve you as our customer, we will make sure we are in mutual harmony and agreement by asking you to accept the change as part of the service delivery.</p>
                    <h3 className="wrapper-main">Data recipients, transfer, and disclosure of Personal Information:</h3>
                    <p>WOWMAKERS will seek your consent prior to using or sharing Personal Information for any purpose beyond the requirement for which it was originally collected.</p>
                    <p>We may share your Personal Information within our affiliates, business partners, service vendors, authorized third-party agents, or contractors located in any part of the world for the purposes of data processing, storage, or to provide a requested service or transaction, after ensuring that such entities are contractually bound by data privacy obligations.</p>
                    <p>When required, WOWMAKERS may disclose Personal Information to external law enforcement bodies or regulatory authorities, in order to comply with legal obligations.</p>

                    <h3 className="wrapper-main">Data security</h3>
                    <p>The security of your information is important to us. WOWMAKERS has implemented reasonable technical and organizational security measures to protect your Personal Information against unauthorised access, accidental loss, improper use and disclosure. We will also take all reasonable precautions to ensure that our staff and employees who have access to information about you have received adequate training.</p>
                    <h3 className="wrapper-main">Data retention</h3>
                    <p>We shall not keep your personal information for any longer than necessary to fulfil our obligations to you or to meet our legitimate business interests or legal requirements.</p>
                    <h3 className="wrapper-main">Privacy Preferences</h3>
                    <p>You may choose not to receive newsletters, invitations and other similar correspondence from us, and/or choose not to have us disclose your personal information to organizations for the purpose of contacting you. You may do this by following the unsubscribe link that we provide in all our e-mail communications.</p>
                    <h3 className="wrapper-main">Information Regarding Your Data Protection Rights Under General Data Protection Regulation (GDPR).</h3>
                    <p>For the purpose of this Privacy Policy, we are a Data Controller of your personal information.</p>
                    <p>If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal information, as described in this Privacy Policy, depends on the information we collect and the specific context in which we collect it. We may process your personal information because:
                    </p>
                    <ul className="privacy">
                        <li>We need to perform a contract with you, such as when you use our services</li>
                        <li>You have given us permission to do so</li>
                        <li>The processing is in our legitimate interests and it's not overridden by your rights</li>
                        <li>For payment processing purposes</li>
                        <li>To comply with the law</li>
                    </ul>
                    <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. In certain circumstances, you have the following data protection rights:</p>
                    <ul className="privacy">
                        <li>
                        The right to access, update or to delete the personal information we have on you</li>
                        <li>The right of rectification</li>
                        <li>The right to object</li>
                        <li>The right of restriction</li>
                        <li>The right to data portability</li>
                        <li>The right to withdraw consent
                        </li>
                    </ul>
                    <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
                    <p>You have the right to complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
                    <h3 className="wrapper-main">California Residents</h3>
                    <p>The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above.</p>
                    <p>We are also required to communicate information about rights California residents have under California law. You may exercise the following rights:

                    </p>
                    <ul className="privacy">
                        <li>
                            Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.</li>
                            <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</li>
                            <li>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected.</li>
                            <li>Request that a business that sells a consumer's personal data, not sell the consumer’s personal data.

                            </li>
                    </ul>
                    <p>
                    If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                    <p>We do not sell the Personal Information of our users.</p>
                    <p>For more information about these rights, please contact us.

                    </p>
                    <h3 className="wrapper-main">Governing Law</h3>
                    <p>The laws of the Union of India, excluding its conflicts of law rules, shall govern this Agreement and your use of the app. Any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the Courts at Ernakulum, Kerala.   All disputes arising out of or in connection with these terms and conditions shall be finally settled under the Arbitration and Conciliation Act, 1996 (including amendments thereto), by a single arbitrator appointed mutually by the Parties.  The place of arbitration shall be at Kochi and the arbitral proceedings shall be in English.</p>
                    <h3 className="wrapper-main">Contact Us</h3>
                    <p>Don't hesitate to contact us if you have any questions.</p>
                    <p> -Via Email:  info@wowmakers.com</p>
                </div>
            </div>
        </div>
      </section>
  </Layout>
)

export default PrivacyPolicy
